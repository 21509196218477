<template>
    <div class="container-fluid">
        <div v-if="isReady" class="container pt-5">
            <video-player :options="videoOptions"/>
        </div>
        <div class="page-title">
            <div class="container clearfix" v-if="event">
                <div class="d-flex desc">
                    <div class="first-column">
                        <h1>{{getVideoName}}</h1>
                        <span>{{getVideoDescription}}</span>
                    </div>
                    <div class="second-column" v-if="videoMeta">
                        <template v-for="(meta, name, index) in videoMeta">
                            <span class="meta-name"  :key="index">
                                {{name}}: {{meta}}
                            </span>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import VideoPlayer from '../../components/video/VideoPlayer';

export default {
  name: 'WatchGameComponent',
  components: { VideoPlayer },
  data() {
    return {
      event: {},
      videoMeta: null,
      videoOptions: {
        autostart: true,
        file: null,
      },
      isReady: false,
    };
  },
  methods: {
    ...mapActions(['GET_VOD_ITEM']),
    getVideoLink(data) {
      if (data.assets.find((item) => item.type == 'video')) {
        let video = data.assets.find((item) => item.type == 'video');
        if (video.metadata) {
          this.videoMeta = video.metadata;
        }
        return video.path;
      }
    },
    loadAssets() {
      this.GET_VOD_ITEM({
        id: this.$route.params.id,
      })
        .then((data) => {
          this.event = data.data;
          this.videoOptions.file = this.getVideoLink(data.data);
          this.isReady = true;
          if (data.data.assets.find((item) => item.type == 'timeline')) {
            this.videoOptions.tracks = [
              {
                file: data.data.assets.find((item) => item.type == 'timeline')
                  .path,
                kind: 'thumbnails',
              },
            ];
          }
        })
        .catch((data) => {
          if (data.status == 404) {
            this.$router.push({ name: 'ErrorPage' });
          }
        });
    },
  },
  computed: {
    getVideoName() {
      if (
        this.event &&
        this.event.assets &&
        this.event.assets.find((item) => item.type == 'video').title
      ) {
        return this.event.assets.find((item) => item.type == 'video').title;
      }
      return '';
    },
    getVideoDescription() {
      if (
        this.event &&
        this.event.assets &&
        this.event.assets.find((item) => item.type == 'video').description
      ) {
        return this.event.assets.find((item) => item.type == 'video')
          .description;
      }
      return '';
    },
  },
  created() {
    this.loadAssets();
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/colors';
.padding-top {
  padding-top: 80px !important;
}

.page-title {
  position: relative;
  padding: 50px 0;
  text-align: left;

  h1 {
    padding: 0;
    margin: 0;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 1px;
    color: $text-secondary;
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  span {
    display: block;
    margin-top: 10px;
    font-weight: 300;
    color: $text-tertiary-middle;
    font-size: 18px;
  }
}
.desc {
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  .first-column {
    overflow: hidden;
  }
  .second-column {
    overflow: hidden;
    text-align: right;
    margin-left: auto;
    .meta-name {
      text-transform: capitalize;
    }
  }
}
</style>
